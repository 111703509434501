import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, message, Select, Card, Input, Modal, Spin } from 'antd';
import FullLayout from '../../../layout/full-layout/full-layout';
import { FormInput, FormContainer } from '../../../module/form';
import { signIn } from '../../../redux/actions/accountAction';
import { languages } from 'constants/constants';
import logo from '../../../assets/images/logo.png';
import appAction from 'redux/actions/appAction';
import './login.scss';
import { getCurrentAccountState, getLanguageState, getMaintennance } from 'redux/selectors';
import { FormattedMessage, injectIntl } from 'react-intl';
import { account } from 'api';
const { Option } = Select;
class Login extends PureComponent {
    state = {
        //allowLogin: ,
        maintenance: {},
        spinning: false,
        visible: false,
        values: {},
    };
    handleSubmit = async (values, isResend) => {
        let reSend = () => {
            this.handleSubmit(null, true);
        };
        try {
            if (!isResend) {
                if (values.username) {
                    values.username = values.username.toLocaleLowerCase();
                    values['language'] = this.props.language || 'en-US';
                }
                this.setState({
                    values: values
                })
            }
            else {
                values = this.state.values;
            }
            await this.login(values);

        } catch (e) {
            message.error(e.message);
        }
    };
    login = async (values) => {
        try {
            if (values) {
                values['language'] = this.props.language || 'en-US';
            }
            let query
            try {
                const { location } = window
                query = new URLSearchParams(location.search);
            } catch (err) {
                console.error('Error getting query params', err)
            }
            const gatewayId = query ? query.get('gatewayId') : undefined
            const login = await this.props.signIn({ ...values, gatewayId });
            if (login && login.isTemporaryPassword) {
                if (login.lastLogin) {
                    setTimeout(
                        () => this.props.history.push('/password-reset'),
                        100,
                    );
                } else {
                    setTimeout(
                        () => this.props.history.push('/change-password'),
                        100,
                    );
                }
            } else {
                if (!login) throw new Error('Login failed, unknown error')
                const continueQuery = query ? query.get('continue') : undefined
                if (continueQuery) {
                    if (!login.authorizationCode) throw new Error('Login failed, unauthorized')
                    //console.log('Login succeeded, pass authorization code to', continueQuery, login)
                    const continueTo = `${continueQuery}${continueQuery.includes('?') ? '&' : '?'}authorization_code=${encodeURIComponent(login.authorizationCode)}`
                    window.location.href = continueTo
                    return
                }

                //setTimeout(() => this.props.history.push("/accounts"), 100)
                setTimeout(() => this.props.history.push('/accounts'), 100);
            }
        } catch (error) {
            message.error(error.message);
        }

    }
    changeLanguage = async (language) => {
        try {
            await this.props.setLanguage(language);
        } catch (error) { }
    };
    async componentDidMount() {
        const maintenance = await account.maintenance();
        let allowLogin = true;
        if (maintenance && maintenance.maintenanceEnable && maintenance.maintenanceStartin<=0) {
            allowLogin = false;
        }
        this.setState({
            allowLogin: allowLogin,
            maintenance: maintenance,
        });
    }
    render() {
        const { intl, ismaintennance, language, currentUser } = this.props;
        const { allowLogin, maintenance, visible } = this.state;
        return (
            <FullLayout className="login-layout">
                {intl.formatMessage({
                    id: 'login.mylogin',
                    defaultMessage: '',
                }) !== 'loading' && (
                        <div className="login">
                            <div className="login-form">
                                {/* <Select
                                    className="language-select"
                                    value={language}
                                    name="language"
                                    onChange={this.changeLanguage}
                                    dropdownClassName="language-select-dropdown"
                                    showArrow={true}
                                >
                                    {languages.map((l) => (
                                        <Option key={l.value} value={l.value}>
                                            <i className={l.icon}></i>
                                        &nbsp; {l.text}
                                        </Option>
                                    ))}
                                </Select> */}
                                <Spin spinning={this.state.spinning}>
                                    <FormContainer
                                        onSubmit={this.handleSubmit}
                                        loading={true}
                                    >
                                        <div className="text-center">
                                            <img
                                                className="logo"
                                                src={logo}
                                                alt="logo"
                                            />
                                        </div>

                                        <div className="text-center">
                                            <h1 className="title">
                                                <FormattedMessage
                                                    id="login.title"
                                                    defaultMessage="Sign in to AMIE Console"
                                                />
                                            </h1>
                                            <h6 className="txt-grey sub-title">
                                                <FormattedMessage
                                                    id="login.subtitle"
                                                    defaultMessage="Enter your details below"
                                                />
                                            </h6>
                                        </div>
                                        <br />
                                        <FormInput
                                            label={
                                                <FormattedMessage
                                                    id="login.username"
                                                    defaultMessage="Username"
                                                />
                                            }
                                            name="username"
                                            disabled={
                                                allowLogin === false && !ismaintennance
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: (
                                                        <FormattedMessage
                                                            id="login.username.required"
                                                            defaultMessage="Please input your username!"
                                                        />
                                                    ),
                                                },
                                            ]}
                                            placeholder={intl.formatMessage({
                                                id: 'login.username',
                                                defaultMessage: 'Username',
                                            })}
                                            maxLength={128}
                                        />
                                        <Link
                                            className="login-form-forgot"
                                            to="/forgot-password"
                                        >
                                            <FormattedMessage
                                                id="login.forgot_password"
                                                defaultMessage="Forgot password ?"
                                            />
                                        </Link>
                                        <FormInput
                                            label={
                                                <FormattedMessage
                                                    id="login.password"
                                                    defaultMessage="Password"
                                                />
                                            }
                                            name="password"
                                            disabled={
                                                allowLogin === false && !ismaintennance
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: (
                                                        <FormattedMessage
                                                            id="login.password.required"
                                                            defaultMessage="Please input your Password!"
                                                        />
                                                    ),
                                                },
                                            ]}
                                            type="password"
                                            placeholder={intl.formatMessage({
                                                id: 'login.password',
                                                defaultMessage: 'Password',
                                            })}
                                        />

                                        <div className="options">
                                            {/* <FormCheckbox
                                    name="remember"
                                    checked={true}
                                    label="Keep me logged in"
                                /> */}

                                            {/* <div class="ant-row ant-form-item form-checkbox">
                                        <div class="ant-col ant-form-item-control-wrapper">
                                            <div class="ant-form-item-control has-success"></div>
                                        </div>
                                    </div> */}

                                            <Link
                                                className="login-form-signup"
                                                to="/signup"
                                            >
                                                <FormattedMessage
                                                    id="login.sign_up"
                                                    defaultMessage="Sign-Up"
                                                />
                                            </Link>
                                        </div>
                                        {allowLogin === false && !ismaintennance && (
                                            <div>
                                                <Card
                                                    title={
                                                        <b>
                                                            {' '}
                                                            <i className="fa fa-warning"></i>{' '}
                                                            <FormattedMessage
                                                                id="login.maintenance_message"
                                                                defaultMessage="Maintenance message"
                                                            />
                                                        </b>
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            (currentUser &&
                                                                currentUser.secondaryColor) ||
                                                            '#F47B20',
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            <pre>
                                                                {maintenance.maintenanceMessage && maintenance.maintenanceMessage.replace(
                                                                        '[time]',
                                                                        ((maintenance &&
                                                                            maintenance.maintenanceStartin) >
                                                                            0
                                                                            ? maintenance.maintenanceStartin
                                                                            : 0) +
                                                                        ' minutes',
                                                                    )}
                                                                {/* {maintenance.maintenanceMessage &&
                                                        maintenance.maintenanceMessage.replace(
                                                            '[time]',
                                                            (maintenance.maintenanceStartin >
                                                            0
                                                                ? maintenance.maintenanceStartin
                                                                : 15) +
                                                                ' minutes',
                                                        )} */}
                                                            </pre>
                                                        }
                                                    </p>
                                                </Card>
                                            </div>
                                        )}
                                        <Button
                                            shape="round"
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                            disabled={
                                                allowLogin === false && !ismaintennance
                                            }
                                        >
                                            <FormattedMessage
                                                id="login.sign_in"
                                                defaultMessage="SIGN IN"
                                            />
                                        </Button>
                                        {/* <p>
            By accessing the AMiE Portal you accept and agree to be bound to the <Link to="/terms-and-conditions">End-User License Agreement Terms and Conditions</Link>
        </p>
        <p>
            © 2020 Spectralink TM | <Link to="/privacy-policy">Privacy Policy</Link>  | <Link to="/terms-of-use">Terms of Use</Link>
        </p> */}
                                    </FormContainer>
                                </Spin>
                            </div>
                        </div>
                    )}
            </FullLayout>
        );
    }
}
function mapStateToProps(state) {
    return {
        language: getLanguageState(state) || 'en-US',
        currentAccount: getCurrentAccountState(state),
        ismaintennance: (getMaintennance(state) || '') === 'maintennance',
    };
}
function mapDispatchToProps(dispatch) {
    return {
        setLanguage: (code) => dispatch(appAction.setLanguage(code)),
        signIn: (params) => dispatch(signIn(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));
