import { AccountConstants } from '../../constants/constants';
import getEnvironmentConfig from 'config/config';
const config = getEnvironmentConfig();

const initState = {
    isLogin: false, //dev
    current: null,
    permissions: [],
    table: {}
};

const accountReducer = (state = initState, action) => {
    switch (action.type) {
        case AccountConstants.SIGN_IN: {
            
            let current = action.data;
            current.avatar = current.avatar
                ? config.S3_RESOURCE_ENDPOINT + current.avatar
                : null; // '/images/mock1.jpg';

            return {
                ...state,
                isLogin: true,
                current: current
            };
        }
        case AccountConstants.GET_ACCOUNTS_TABLE_DATA: {
            return {
                ...state,
                table: action.data
            };
        }
        case AccountConstants.LOGOUT: {
            return {
                ...state,
                isLogin: false,
                current: null
            };
        }
        case AccountConstants.GET_PERMISSIONS: {
            return {
                ...state,
                permissions: action.payload
            };
        }
        case AccountConstants.UPDATE_CURRENT_AVATAR: {
            return {
                ...state,
                current: {
                    ...state.current,
                    avatar: action.payload
                }
            };
        }
        case AccountConstants.UPDATE_ACCOUNT_BRANDING_DATA: {
             
             if (state.current.accountId === action.payload.accountId) {
                return {
                    ...state,
                    current: {
                        ...state.current,
                        appLogo: action.payload.appLogo,
                        primaryColor: action.payload.primaryColor,
                        secondaryColor: action.payload.secondaryColor,
                        tertiaryColor: action.payload.tertiaryColor,
                        quaternaryColor: action.payload.quaternaryColor,
                    }
                };
            }
            else{return state}
        }
        default:
            return state;
    }
};

export default accountReducer;
